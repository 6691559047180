@import "../../resets/textarea";

.label {
	@include reset-textarea;

	background: #e8e8e8;
	border-radius: 0.5em;
	color: rgba(25, 25, 25, 1);
	display: flex;
	padding: 1em 0 1em 0.5em;
	align-items: center;
	z-index: var(--high);
	position: relative;

	textarea {
		padding: 0 1rem 0 0.5rem;
		box-sizing: border-box;
		font-size: inherit;
	}
}
