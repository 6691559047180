.wrapper {
	position: relative;
}

.container {
	position: absolute;
	color: #fff;
	z-index: var(--highest);
	padding: 0.25em;
	width: fit-content;
	width: 260px;
	max-width: 100vw;
	top: 0;
	right: 0;
	box-sizing: content-box;
}

.padding {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	background: #242424;

	border-top-right-radius: 21px;
}

.trigger {
	height: 42px;
	width: 42px;
	display: block;
}

.accordion {
	overflow: hidden;
	width: 100%;
}

.list {
	display: flex;
	flex-direction: column;
	gap: 0.5em;
	width: 100%;
	padding: 0.5rem;
	padding-bottom: 0.75rem;

	li {
		display: flex;
		align-items: center;
		gap: 0.5em;
	}
}

.pin {
	height: 24px;
	width: 24px;
	display: block;
}
