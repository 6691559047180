.container {
	$radius: 0.35em;

	display: flex;
	list-style-type: none;
	padding: 0;
	margin: 0;
	border-radius: $radius;
	background: linear-gradient(#2f2f2f, #2e2e2e);
	box-shadow: inset 0 0 2px 0px #1d1d1d;

	.option {
		padding: 0.25em;
		width: 100%;
		text-align: center;
		border-radius: $radius;
		padding: 0.25em 0.5em;
		min-height: 44px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		user-select: none;

		&--active {
			background: linear-gradient(#2b9cfb, #35a1fb);
		}
	}
}
