.inline {
	display: inline;
}

.normal {
	height: 0.8em;
	width: auto;
}

.medium {
	height: 1rem;
	width: 1rem;
}

.block {
	display: block;
	margin: auto;
}

.control {
	height: 1.75rem;
	width: 1.75rem;
}

.big {
	height: 1.8rem;
	width: 1.8rem;
}

.hbig {
	height: 1.8rem;
}

.spaced {
	margin-right: 0.5em;
}
