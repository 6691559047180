@import "./../resets/document";
@import "./../breakpoints/tablet.module";

:root {
	--normal: 100;
	--medium: 200;
	--high: 300;
	--higher: 400;
	--highest: 500;
	--infinity: 600;
}

* {
	box-sizing: border-box;
}

html,
body,
#root {
	height: 100%;
}

html,
body {
	@include reset-document;

	font-weight: 400;
	background-color: rgb(25, 25, 25, 0.9);
	color: white;

	@include breakpoint-tablet {
		font-size: 16px;
	}
}

a {
	color: inherit;
}

h1,
h2,
h3,
h4,
h5 {
	font-weight: inherit;
}

h5 {
	font-size: inherit;
	margin-top: 1em;
	margin-bottom: 0.5em;

	& + p {
		margin-top: 0.5em;
	}
}

p {
	line-height: 1.4;
}

mark {
	color: rgb(43, 156, 251);
	background-color: transparent;
}

strong {
	font-weight: 400;
}

.underline {
	text-decoration: underline;
}
