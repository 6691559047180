.preview-code {
	padding: 0.5rem;
	border: 1px solid #373737;
	margin: 1.5rem 0 0 0;
	border-radius: 0.5rem;

	& > * + * {
		margin-top: 0.25rem;
	}

	& &-input {
		background-color: #0a0a0a;
		color: white;
	}
}
