$breakpoint-tablet: unquote("only screen and (min-width: 768px)");

:export {
	breakpoint-tablet: $breakpoint-tablet;
}

@mixin breakpoint-tablet {
	@media #{$breakpoint-tablet} {
		@content;
	}
}
