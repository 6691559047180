@import "../../resets/button";

.container {
	@include reset-button;

	width: 100%;
	max-width: 200px;
	padding: 0.5em 1em;
	border-radius: 0.5em;

	&--default {
		background-color: rgb(55, 55, 55);
	}

	&--light {
		background-color: rgb(255, 255, 255);
		color: rgb(55, 55, 55);
	}

	&--alert {
		background-color: rgb(43, 156, 251);
		color: rgb(255, 255, 255);
	}

	&--danger {
		background-color: rgb(215, 79, 99);
		color: rgb(255, 255, 255);
	}

	&--text {
		width: auto;
		max-width: auto;
		padding: 0;
		background: none;
	}

	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
}
