.container {
	cursor: pointer;
	padding: 0;
	position: relative;

	& > div {
		padding-top: 50%;
	}

	& > .square {
		padding-top: 100%;
	}

	& > div {
		height: 100%;
		width: 100%;
		position: relative;

		& > div {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			border-radius: 0.5rem;
			overflow: hidden;
			background: rgb(var(--white));
		}

		img {
			height: 100%;
			width: 100%;
			object-fit: contain;
			object-position: center;
			display: block;
		}
	}

	.close {
		position: absolute;
		top: 0;
		right: 0;
		background-color: #373737;
		border-radius: 50%;
		padding: 0.5rem;
		box-shadow: 0 0 12px 2px rgba(25, 25, 25, 0.12);
		transform: translate3d(25%, -25%, 0);
		height: 44px;
		width: 44px;
		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			height: 0.75rem;
			width: 0.75rem;
			display: block;
		}
	}
}
