.container {
	background: linear-gradient(lighten(rgb(10, 10, 10), 9%), lighten(rgb(10, 10, 10), 9.5%));
	padding: 0 0.25rem;
	display: flex;
	column-gap: 1rem;
	justify-content: space-between;
	align-items: center;
	border-top: 1px solid lighten(rgb(10, 10, 10), 15%);
	box-shadow: 0 0 12px 2px rgba(25, 25, 25, 0.12);

	& > * {
		display: flex;
		column-gap: 0.25rem;
		justify-content: flex-start;

		&:first-child {
			flex-grow: 1;
		}
	}

	.item {
		flex-grow: 0;
		display: flex;
		column-gap: 0.5em;
		align-items: center;
		cursor: pointer;
		justify-content: center;

		span {
			white-space: nowrap;
		}

		svg {
			display: block;
			height: 44px;
			width: 44px;

			path {
				fill: white;
			}
		}
	}
}
