@mixin reset-textinput {
	input[type="text"],
	input[type="email"] {
		width: 100%;
		font-size: inherit;
		border: none;
		background: transparent;
		padding: 0;
		font-family: inherit;
	}
}
