.container {
	width: 100%;
}

.history {
	display: grid;
	column-gap: 0.5rem;
	row-gap: 0.5rem;
	grid-template-columns: repeat(2, 1fr);
	margin-top: 1rem;

	&:empty {
		margin-top: 0;
	}
}
