@font-face {
	font-family: "SuisseIntl";
	src: url("./../assets/SuisseIntl-Regular.woff2") format("woff2"),
		url("./../assets/SuisseIntl-Regular.woff") format("woff");
}

@font-face {
	font-family: "SuisseIntl";
	src: url("./../assets/SuisseIntl-Light.woff2") format("woff2"),
		url("./../assets/SuisseIntl-Light.woff") format("woff");
	font-weight: 300;
}

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

* {
	outline: none;
}

html,
body {
	font-size: clamp(14px, 2.5vw, 16px);
	font-family: "SuisseIntl", sans-serif;
}

a {
	text-decoration: none;
}

ul,
li {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

p a {
	cursor: pointer;
	text-decoration: none;
	border-bottom: 1px solid;
	border-color: inherit;
}

h1 {
	font-size: 2rem;
	margin-top: 0.75em;
	margin-bottom: 0.5em;
	font-weight: 300;
	line-height: 1.1;
	color: #339ef8;
}

.flex {
	display: flex;
	column-gap: 0.25rem;
	row-gap: 0.25rem;
}

.wrap {
	flex-wrap: wrap;
}

.control {
	border-radius: 0.5rem;
	height: 44px;
	width: 44px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.2);
	flex-grow: 0;
	flex-shrink: 0;
	cursor: pointer;
	border-width: 1px;
	border-color: inherit;
	border-style: solid;

	svg {
		height: 100%;
		width: 100%;
	}
}

.lightblack {
	background-color: #1f1f1f;
	border-color: #282828;

	svg {
		path {
			fill: white;
		}
	}
}

div > :first-child {
	margin-top: 0;
}

div > :last-child {
	margin-bottom: 0;
}

.base {
	font-size: 1rem;
	letter-spacing: 0.03em;
}

.small {
	font-size: 0.9em;
	letter-spacing: 0.02em;
}

.gray {
	color: rgb(151, 151, 151);
}

.blue {
	color: #339ef8;

	&--bg {
		background: #339ef8;
	}
}

.white {
	color: white;

	&--bg {
		background: white;
	}
}

.red {
	color: rgb(215, 79, 99);
}

.center {
	text-align: center;
}

.left {
	text-align: left;
}
