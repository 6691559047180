@import "./../../breakpoints/tablet.module";

.portal {
	overflow: auto;
	height: 100%;
	width: 100%;
	z-index: var(--higher);
	top: 0;
	left: 0;
	position: fixed;
	background: #212c3563;
	overscroll-behavior: none;
	-webkit-overflow-scrolling: touch;

	&::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.container {
	display: flex;
	flex-direction: column;
	padding: 4rem 0 0;
	width: 100%;
	min-height: 100%;
	align-items: center;

	@include breakpoint-tablet {
		padding: 1rem 0.5rem;
	}

	.content {
		max-height: 100%;
		padding-bottom: 0;
		padding-top: 1.5rem;
		padding-bottom: 1rem;
		width: 100%;
		box-shadow: 0 0 12px 2px rgba(25, 25, 25, 0.12);
	}

	&--top {
		justify-content: flex-start;
	}

	&--center {
		justify-content: center;
		padding: 2rem 0.5rem;

		.content {
			border-radius: 1rem 1rem 2.4rem 1rem;
		}
	}

	&--bottom {
		justify-content: flex-end;

		.content {
			border-radius: 1rem 1rem 0 0;

			@include breakpoint-tablet {
				border-radius: 1rem 1rem 2.4rem 1rem;
			}
		}
	}

	@include breakpoint-tablet {
		&--top,
		&--center,
		&--bottom {
			justify-content: center;
		}
	}

	.controls {
		position: sticky;
		bottom: 1rem;
		width: fit-content;
		z-index: var(--higher);
		margin-left: auto;
		padding: 0;
		background-color: transparent;
		padding-top: 1rem;

		.close {
			$min-size: 44px;

			box-shadow: 0 0 12px 2px rgba(25, 25, 25, 0.12);
			min-width: $min-size;
			min-height: $min-size;
			padding: 0 1rem;
			border-radius: $min-size / 2;
			background-color: #373737;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}
