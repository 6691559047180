.textInput {
	text-align: left;

	& > :first-child {
		margin-top: 0;
	}

	& > :last-child {
		margin-bottom: 0;
	}

	p {
		margin-bottom: 0.25em;
	}
}

.my-position {
	display: flex;
	column-gap: 0.5rem;
	align-items: center;

	svg {
		height: calc(44px - 1.5rem);
		width: calc(44px - 1.5rem);
	}
}

.option {
	white-space: nowrap;
	background: rgb(55, 55, 55);
	width: 100%;
	text-align: left;
	border-radius: 5px;
	cursor: pointer;
	padding: 0.75rem 0.5rem;
	min-height: 44px;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: none;

	&--important {
		color: #2b9cfb;
	}
}
