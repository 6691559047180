@import "./../ui/breakpoints/tablet.module";

.loader {
	position: fixed;
	height: 100%;
	width: 100%;
	background-color: rgba($color: #000000, $alpha: 0.7);
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 900;

	svg {
		width: 100%;
		max-width: 64px;
		height: 100%;
		max-height: 64px;
		object-fit: contain;
		object-position: center;
		animation: spin 2.5s infinite;
		animation-delay: 1s;
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg) scale(1);
		}
		12.5% {
			transform: rotate(-30deg) scale(1.1);
		}
		60% {
			transform: rotate(390deg) scale(1.1);
		}
		68.75% {
			transform: rotate(350deg) scale(1);
		}
		75% {
			transform: rotate(360deg) scale(1);
		}
		100% {
			transform: rotate(360deg) scale(1);
		}
	}
}

.main {
	height: 100%;

	.aside {
		position: relative;
		z-index: 20;
		box-shadow: 0 0 12px 4px rgb(25 25 25 / 15%);
		display: flex;
		flex-direction: column;

		.center {
			position: relative;
			flex-grow: 1;

			& > * {
				padding-bottom: 0.25rem;
			}

			.floating {
				padding: 0 0.25rem;
			}
		}
	}

	.canvas {
		position: relative;
		z-index: 300;

		overflow: visible !important;

		@include breakpoint-tablet {
			z-index: 10;
		}

		overflow: hidden;
	}

	--sash-hover-size: 0px;
	--sash-size: 40px;
}

.draggable {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;

	&::before {
		background-color: #e8e8e8;
		border-radius: 6px;
		content: "";
		display: block;
		height: 5px;
		top: calc(8px + env(safe-area-inset-top));
		width: 36px;
	}
}

// Map
.map {
	position: absolute;
	height: 100%;
	width: 100%;

	.center {
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);
		height: 7px;
		width: 7px;
		position: absolute;
		z-index: var(--higher);
		background: rgb(25, 25, 25);
		border-radius: 50%;
	}

	&--bottom {
		position: absolute;
		width: 100%;
		bottom: 0;
		z-index: var(--higher);
		padding: 0.25rem;
	}
}

.bottom {
	.routing {
		padding: 0.5rem;
		display: flex;
		flex-direction: column;

		.controls {
			display: flex;
		}

		.control {
			flex: 0;
			flex-grow: 0;
			flex-shrink: 0;
			margin: 0.5rem;
			border-radius: 0.5rem;

			&--route {
				background: rgb(255, 255, 255);
				padding: 0.5rem;

				&--active {
					background: rgb(43, 156, 251);
				}

				&--disabled {
					opacity: 0.3;
				}
			}

			&--close {
				background: rgb(227, 94, 94);
				padding: 1rem;
			}
		}

		.store {
			display: flex;

			.excerpt {
				flex: 1;
			}
		}
	}

	.toolbar {
		padding: 0 0.5rem;

		@include breakpoint-tablet {
			padding: 0;
		}

		.inner {
			border-radius: 0.5rem 0.5rem 0 0;
			background-color: rgba(255, 255, 255, 1);
			color: rgb(25, 25, 25);
			padding: 0.5rem;
			display: flex;
			column-gap: 1rem;
			justify-content: space-between;

			@include breakpoint-tablet {
				border-radius: 0;
			}

			& > div {
				display: flex;
				column-gap: 0.5rem;
			}

			.control {
				padding: 0.5rem;
			}
		}
	}
}

.pointer {
	height: 15px;
	width: 15px;
	position: absolute;
	margin: auto;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: var(--highest);
	background-image: url("../assets/pointers/search.svg");
	background-position: center;
	background-size: contain;
}
