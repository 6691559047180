.refresh {
	position: absolute;
	bottom: 0.75rem;
	padding: 0.25rem;
	z-index: var(--highest);
	text-align: center;
	left: 50%;
	transform: translateX(-50%);

	button {
		background: linear-gradient(
			lighten(rgb(43, 156, 251), 0%),
			lighten(rgb(43, 156, 251), 2%)
		);
		padding: 0.25em 0.5em;
		border-radius: 0.5rem;
		height: 44px;
		display: flex;
		align-items: center;
		justify-content: center;
		box-shadow: 0 0 12px 2px rgba(25, 25, 25, 0.12);
		border: 0;
		color: white;
		font-size: inherit;
	}
}
