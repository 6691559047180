:root {
	--red: 215, 79, 99;
	--white: 255, 255, 255;
	--blue: 51, 158, 248;
}

.h6 {
	font-size: 1.2rem;
}

.small {
	font-size: 0.85rem;
	line-height: 1.3;
}

.rounded {
	border-radius: 0.5rem;
}

.theme {
	&-red {
		background-color: rgba(var(--red), 0.7);
		border: 1px solid rgb(var(--red));
		color: rgb(var(--white));
	}

	&-blue {
		background-color: rgba(var(--blue), 0.7);
		border: 1px solid rgb(var(--blue));
		color: rgb(var(--white));
	}
}

.p {
	&top {
		padding-top: 0.75rem;
	}

	&bottom {
		padding-bottom: 0.75rem;
	}

	&left {
		padding-left: 0.5rem;
	}

	&right {
		padding-right: 0.5rem;
	}

	&vertical {
		@extend .ptop;
		@extend .pbottom;
	}

	&horizontal {
		@extend .pleft;
		@extend .pright;
	}
}

.m {
	&top {
		margin-top: 0.75rem;
	}

	&bottom {
		margin-bottom: 0.75rem;
	}

	&left {
		margin-left: 0.5rem;
	}

	&right {
		margin-right: 0.5rem;
	}

	&vertical {
		@extend .mtop;
		@extend .mbottom;
	}

	&horizontal {
		@extend .mleft;
		@extend .mright;
	}
}

.fit {
	&-content {
		width: fit-content;
	}

	&-container {
		width: 100%;
	}
}

.no-interaction {
	pointer-events: none;
	user-select: none;
}

.pre {
	white-space: pre-wrap;
}

.blue {
	color: rgb(var(--blue));
}

.uppercase {
	text-transform: uppercase;
}

.slick-prev,
.slick-next {
	z-index: 100;

	&::before {
		opacity: 1 !important;
		color: rgb(var(--blue)) !important;
	}
}

.slick-prev {
	left: 10px !important;
}

.slick-next {
	right: 10px !important;
}
