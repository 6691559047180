@import "./../../../ui/breakpoints/tablet.module.scss";

.location-wrapper {
	display: flex;
	flex-direction: column;
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
}

.location-controls {
	flex-direction: row;
	padding: 0.25rem;
	padding-bottom: 0;
	row-gap: 0.25rem;
	column-gap: 0.25rem;
	z-index: var(--infinity);
	display: flex;
	user-select: none;

	& > :nth-child(1) {
		flex-grow: 1;
	}

	.control {
		background-color: rgba(255, 255, 255, 1);
		border-radius: 0.5rem;
		box-shadow: 0 0 12px 2px rgb(25 25 25 / 12%);
	}

	.button {
		color: rgb(25, 25, 25);
		padding: 0.25em;
		height: 44px;
		width: 44px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		&--disabled {
			opacity: 0.5;
		}

		&--active {
			background-color: rgb(43, 156, 251);
		}
	}

	@include breakpoint-tablet {
		display: none;
	}
}

.map-info {
	display: flex;
	flex-direction: row;
	column-gap: 0.5em;
	justify-content: space-between;
}
