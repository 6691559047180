@import "../../resets/interaction";

.container {
	@include reset-interaction;

	&--disabled {
		opacity: 0.5;
	}

	display: flex;
	position: relative;

	input {
		display: block;
		position: absolute;
		height: 1px;
		width: 1px;
		opacity: 0;
		margin: auto;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}

	input ~ .switch {
		$height: 35px;

		height: $height;
		width: $height * 1.8;
		display: flex;
		justify-content: flex-start;
		border-radius: $height / 2;

		& > * {
			width: $height;
			height: $height;
			padding: 2px;

			&::after {
				content: "";
				border-radius: 50%;
				width: 100%;
				height: 100%;
				display: block;
				background: white;
			}
		}
	}

	// input:disabled ~ .label {
	// 	opacity: 0.3;
	// }

	.switch[data-ison="false"] {
		background-color: rgb(55, 55, 55);
	}

	.switch[data-ison="true"] {
		justify-content: flex-end;
		background-color: rgb(43, 157, 250);
	}
}
