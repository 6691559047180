.container {
  .modal {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    background: transparent;
    border-radius: 1rem;

    &::after,
    &::before {
      display: none;
    }
  }

  .banner {
    display: block;
    width: 100%;
    height: auto;
  }
}
