.target {
	column-gap: 0.5rem;
	display: flex;
	flex-direction: column;
	padding: 0.25rem;
	z-index: var(--highest);
	row-gap: 0.25rem;

	.info {
		display: flex;
		column-gap: 0.25rem;

		.logo {
			width: clamp(93px, 21vw, 171px);
			border-radius: 0.5rem;
			overflow: hidden;
			box-shadow: 0 0 12px 2px rgb(25 25 25 / 12%);
		}

		.counter {
			text-align: center;
			background-color: rgba(255, 255, 255, 1);
			color: rgb(25, 25, 25);
			border-radius: 0.5rem;
			display: flex;
			align-items: center;
			justify-content: center;
			width: clamp(60px, 15vw, 75px);
			box-shadow: 0 0 12px 2px rgb(25 25 25 / 12%);

			& > div {
				padding-top: 100%;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;

				& > div {
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					width: 100%;
					display: flex;
					flex-direction: column;
					row-gap: 0.15em;
					justify-content: center;
					align-self: stretch;

					span {
						white-space: nowrap;
					}
				}
			}
		}
	}

	.labels {
		display: grid;
		column-gap: 0.25rem;
		row-gap: 0.25rem;
		grid-template-columns: repeat(4, 3rem);

		.label {
			width: 100%;
			height: fit-content;
			object-fit: contain;
			object-position: center;
			overflow: hidden;
			background-color: transparent;
			border-radius: 0.5rem;
			box-shadow: 0 0 12px 2px rgb(25 25 25 / 12%);
			background: rgb(var(--white));
		}
	}
}
