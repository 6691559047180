.center {
	text-align: center;
}

.left {
	text-align: left;
}

.right {
	text-align: right;
}

.small {
	font-size: 0.75em;
	font-weight: 400;
}

.medium {
	font-size: 1.28em;
}

.reset {
	text-decoration: inherit;
	color: inherit;
	border: 0;
}

.gray {
	color: rgb(126, 126, 126);
}

.red {
	color: rgb(215, 79, 99);
}

.uppercase {
	text-transform: uppercase;
}

.text {
	& > :first-child {
		margin-top: 0;
	}

	& > :last-child {
		margin-bottom: 0;
	}
}
