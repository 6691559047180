.container {
	background: rgb(25, 25, 25);
	padding: 1rem;
	border-radius: 1rem;
	max-width: 480px;
	box-shadow: 0 0 6px rgba(58, 58, 58, 0.1);

	& > :first-child {
		margin-top: 0;
	}

	& > :last-child {
		margin-bottom: 0;
	}
}
