.container {
	background: linear-gradient(lighten(rgb(10, 10, 10), 10%), lighten(rgb(10, 10, 10), 12%));
	border-bottom: 2px solid rgb(var(--white));
	box-shadow: 0 0 12px 2px rgba(25, 25, 25, 0.12);
	display: flex;
	flex-direction: column;
	row-gap: 0.25rem;

	p {
		margin: 0;
		margin-bottom: 0.25em;
	}
}
