@import "../../resets/interaction";

.container {
	@include reset-interaction;
	cursor: pointer;

	background: #373737;
	margin: 0.5em 0;
	border-radius: 0.5rem;
	padding: 1em 0.5em;

	& > * {
		margin: 0;
	}

	.heading {
		display: flex;
		justify-content: space-between;
		align-items: center;

		& > * {
			margin: 0;
		}
	}

	.content {
		overflow: hidden;

		& > :first-child {
			margin-top: 0.5rem;
		}

		& > :last-child {
			margin-bottom: 0;
		}
	}
}
