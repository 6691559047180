@import "./../../../ui/breakpoints/tablet.module";

.fallback p {
	margin: 1rem 0;
	padding: 0 0.5rem;
}

.list {
	display: flex;
	flex-direction: column;
	position: absolute;
	height: 100%;
	overflow-y: auto;
	width: 100%;
	scroll-snap-type: y mandatory;
	padding: 0 0.25rem;

	--scroll-size: 7px;
	--scroll-radius: 10px;
	--scroll-track: rgb(255 255 255 / 10%);
	--scroll-thumb-color: rgba(var(--white), 1);
}

.scroll {
	scrollbar-color: var(--scroll-thumb-color, grey) var(--scroll-track, transparent);
	scrollbar-width: thin;
}
.scroll::-webkit-scrollbar {
	width: var(--scroll-size, 10px);
	height: var(--scroll-size, 10px);
}
.scroll::-webkit-scrollbar-track {
	background-color: var(--scroll-track, transparent);
	// border-radius: var(--scroll-track-radius, var(--scroll-radius));
}
.scroll::-webkit-scrollbar-thumb {
	background-color: var(--scroll-thumb-color, grey);
	background-image: var(--scroll-thumb, none);
	// border-radius: var(--scroll-thumb-radius, var(--scroll-radius));
}
