@import "../../resets/textinput";

.clearfix {
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	z-index: var(--normal);
}

.wrapper--open.wrapper--up {
	.label {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
}

.wrapper--open.wrapper--down {
	.label {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
}

.wrapper {
	position: relative;
}

.label {
	@include reset-textinput;

	background: white;
	border-radius: 0.5em;
	color: rgba(25, 25, 25, 1);
	display: flex;
	padding: 0.5rem;
	align-items: center;
	z-index: var(--high);
	position: relative;
	min-height: 44px;
	cursor: text;

	input[type="text"],
	input[type="email"] {
		padding: 0 1rem 0 0.5rem;
		box-sizing: border-box;
		font-size: inherit;
	}
}

.options {
	position: fixed;
	z-index: var(--highest);
	overflow: hidden;

	.list {
		display: flex;
		flex-direction: column;
		background: rgb(25, 25, 25);
		row-gap: 0.25em;
		flex-direction: column;
		padding: 0.25rem;
		border-radius: 0.5rem;
		width: 100%;
		max-width: none;
	}

	&--up {
		transform: translateY(-100%);

		.list {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	}

	&--down {
		.list {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
	}
}
