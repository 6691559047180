@import "./../../ui/breakpoints/tablet.module";

.directions-from {
	background: linear-gradient(#242424, #292929);
	border-radius: 0.5rem;
	padding: 0.5rem;
	margin-bottom: 0.25em;

	p {
		margin: 0;
		margin-bottom: 0.25em;
	}

	@include breakpoint-tablet {
		display: none;
	}
}

.wrapper {
	padding-top: 0.25rem;
	scroll-snap-align: start;
	display: flex;
	column-gap: 0.25rem;
	width: 100%;
	flex-wrap: wrap;

	& > :nth-child(1) {
		flex-grow: 1;
		flex-shrink: 1;
	}

	& > :nth-child(2) {
		flex-shrink: 0;
		flex-grow: 0;
	}
}

.container {
	border-radius: 0.5rem;
	padding: 0.75rem 0.5rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	max-width: none;
	background: linear-gradient(lighten(rgb(10, 10, 10), 9%), lighten(rgb(10, 10, 10), 9.5%));
	box-shadow: 0 0 12px 2px rgba(25, 25, 25, 0.12);
	flex-grow: 0;
	flex-shrink: 0;
	width: 100%;
	user-select: none;
	cursor: poinrter;
	row-gap: 0.5rem;

	&--selected {
		color: rgb(25, 25, 25);
		background: #ffffff;

		.actions-controls {
			display: flex;
		}
	}

	&--routing {
		background: rgb(43, 156, 251);
		color: rgba(255, 255, 255, 1);

		.actions-controls {
			.control {
				background: #2794ef;

				&--active {
					background: #1b79c6;
				}
			}
		}
	}

	& > :nth-child(1) {
		display: flex;
		column-gap: 1rem;
		justify-content: space-between;

		& > :nth-child(1) {
			flex-grow: 1;
			overflow: hidden;
		}

		& > :nth-child(2) {
			flex-grow: 0;
			flex-shrink: 0;
			width: 100px;
			overflow: hidden;
		}

		p {
			width: 100%;
			margin: 0;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		.street {
			// white-space: nowrap;
		}

		.locality {
			white-space: nowrap;
		}

		.controls {
			display: flex;
			align-items: center;
			flex: 0;
			flex-grow: 0;
			flex-shrink: 0;

			.extend {
				display: flex;
				padding: 0.75rem;
				border-radius: 0.5rem;
			}
		}
	}
}

.actions-controls {
	flex-direction: row;
	row-gap: 0.25rem;
	column-gap: 0.25rem;
	display: none;
	flex-wrap: wrap;
	justify-content: space-between;

	& > * {
		display: flex;
		row-gap: 0.25rem;
		column-gap: 0.25rem;
	}

	.control {
		background-color: darken(#ffffff, 3%);
		border-radius: 0.5rem;
		height: 44px;
		width: 44px;
		display: flex;
		align-items: center;
		justify-content: center;
		box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.1);
		flex-grow: 0;
		flex-shrink: 0;
		cursor: pointer;
		border: 0;

		svg {
			height: 100%;
			width: 100%;

			path: {
				fill: white;
			}
		}

		&--blue {
			svg {
				path {
					fill: white;
				}
			}
		}

		&--red {
			background-color: #e35e5e;
			color: #e35e5e;

			svg {
				path {
					fill: white;
				}
			}
		}
	}

	&--routing {
		width: 100%;
		display: flex;
		margin-top: 0.25rem;

		& > .control {
			width: 100%;
		}
	}
}
