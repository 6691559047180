.modal {
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
}

.carousel {
	display: flex;
	overflow-x: auto;
	padding-bottom: 0.25rem;
	user-select: none;
	-webkit-overflow-scrolling: touch;

	@media (hover: none) {
		scroll-snap-type: x mandatory;
		scroll-behavior: smooth;
	}

	& > div {
		width: 80%;
		flex-grow: 0;
		flex-shrink: 0;

		& > div {
			padding-top: 75%;
			position: relative;

			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
				object-position: center;
				display: block;
				position: absolute;
				top: 0;
				left: 0;
			}
		}
	}

	& {
		--scroll-size: 5px;
		--scroll-radius: 10px;
		--scroll-track: rgb(255 255 255 / 10%);
		--scroll-thumb-color: #fff;
		scrollbar-color: var(--scroll-thumb-color, grey) var(--scroll-track, transparent);
		scrollbar-width: thin;
	}
	&::-webkit-scrollbar {
		display: initial;
		width: var(--scroll-size, 10px);
		height: var(--scroll-size, 10px);
	}
	&::-webkit-scrollbar-track {
		background-color: var(--scroll-track, transparent);
		border-radius: var(--scroll-track-radius, var(--scroll-radius));
	}
	&::-webkit-scrollbar-thumb {
		background-color: var(--scroll-thumb-color, grey);
		background-image: var(--scroll-thumb, none);
		border-radius: var(--scroll-thumb-radius, var(--scroll-radius));
	}
}

#brands {
	margin-top: 1rem;
}

.grid {
	display: grid;
	column-gap: 0.5rem;
	row-gap: 0.5rem;
	grid-template-columns: repeat(2, 1fr);
	margin-top: 1rem;

	&:empty {
		margin-top: 0;
	}
}
