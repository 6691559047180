.referrals {
	display: flex;
	flex-direction: column;
	margin: 1rem 0 0 0;
	row-gap: 0.5rem;

	li + li {
		margin-top: 0.25rem;
	}

	.referral {
		border-radius: 0.5rem;
		background-color: rgb(55, 55, 55);
	}
}

.location {
	margin: 1rem 0;
}
