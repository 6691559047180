.main {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 1rem;
	padding-bottom: 8rem;
}

.logo {
	margin-bottom: 3rem;
	width: 100%;
	max-width: 240px;
}

.h1,
.p {
	color: rgb(33 145 239);
	margin: 0;
	text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.2),
		0px -1px 0px rgba(0, 0, 0, 0.25);
}

.h1 + .p {
	margin-top: 0.5em;
}

.h1 {
	font-size: 1.953rem;
}

.p {
	font-size: 1rem;
}
