@import "../../resets/list";
@import "../../resets/interaction";

.container {
	@include reset-list;
	@include reset-interaction;

	display: block;

	.input {
		display: none;
	}

	.label {
		border: 1px solid rgb(232, 232, 232);
		border-radius: 0.5em;
		padding: 1em 0.5em;
		cursor: pointer;
	}

	.options {
		display: flex;
		flex-direction: column;
		top: 0;
		right: 0;
		position: fixed;
		height: 100%;
		width: 100%;
		z-index: var(--infinity);
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
		overscroll-behavior: none;

		&::-webkit-scrollbar {
			display: none;
		}
		-ms-overflow-style: none;
		scrollbar-width: none;

		.translator {
			width: 100%;
			max-width: 480px;
			padding: 1rem;

			.list {
				display: flex;
				flex-direction: column;
				row-gap: 0.5em;
				width: 100%;
				background: rgb(25, 25, 25);
				border-radius: 1em;
				padding: 1em;
			}

			.option {
				white-space: nowrap;
				background: #373737;
				width: 100%;
				text-align: center;
				border-radius: 0.5em;
				cursor: pointer;

				&--selected {
					background: #339ef8;
				}
			}
		}
	}
}
