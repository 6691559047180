.disclaimer {
	margin: 1rem 0;
	padding: 1.5em 2em;
	border-radius: 1rem;

	&--alert {
		background-color: rgb(25, 25, 25);
		box-shadow: inset 0 0 20px 0px #00000063;
	}

	& > :first-child {
		margin-top: 0;
	}

	& > :last-child {
		margin-bottom: 0;
	}
}
